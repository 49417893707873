<template>
	<div class="container">
		<div class="w1200">
			<div class="nav_top">当前位置：<router-link :to="{path:'/'}">首页</router-link> > 预约服务</div>
			<div class="content">
				<div class="item" @click="toDetail(0)"><span>个人预约</span><label>PERSONAL APPOINTMENT</label></div>
				<div class="item" @click="toDetail(1)"><span>团队预约</span><label>TEAM  APPOINTMENT</label></div>
<!--				<div class="item" @click="toDetail(2)"><span>政务接待</span><label>Government RECEPTION</label></div>-->
			</div>


		</div>

	</div>
</template>

<script>
	import urlobj from '@/api/baseurl.js'
	export default {
		data() {
			return {

			}
		},
		created() {

		},
		mounted() {
		},
		methods: {
			toDetail(type) {
				this.$router.push({ path: "/Reservation/xuzhi",query:{type:type}});
			}


		}
	}
</script>

<style lang="scss" scoped>
.container{
	.w1200{
		min-height: calc(100vh - 354px);
		background: #FFFFFF;
		.content{
			min-height: calc(100vh - 408px);
			width: 100%;
			padding: 0 100px;
			display: flex;
			align-items: center;
			justify-content: center;
			.item{
				width: 270px;
				height: 400px;
				margin: 0 50px;
				background: url('../../assets/images/yuyuefuwu_bj.png') no-repeat;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				cursor: pointer;
				span{
					display: inline-block;
					width: 48px;
					font-size: 48px;
					color: #7D2626;
				}
				label{
					position: absolute;
					color: #ABABAB;
					display: inline-block;
					font-size: 12px;
					transform: rotate(90deg);
					right: 10px;
					top: 70%;
					width: 160px;
				}

			}
		}

	}
}

</style>
